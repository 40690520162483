import React, { useState } from "react";
import "./Activities.scss";

const activities = [
  {
    name: "Sailing",
    description: "5-hour introductory and advanced sailing courses covering basic techniques to advanced maneuvers. Suitable for all skill levels.",
    price: '€195 per course',
    image: "Optimist",
  },
  {
    name: "ROWING",
    description: "Coastal rowing sessions using specialized boats designed for open water conditions. Includes introductory and advanced training.",
    price: '550 EGP per session',
    image: "rowing",
  },
  {
    name: "KAYAKING",
    description: "Explore the coastline with our kayaks. Choose between single or double kayaks for your water adventure.",
    price: '400 - 500 EGP/hour',
    image: "kayaking",
  },
  {
    name: "SEA CRUISER",
    description: "Multihull electric watercraft suitable for all ages. Enjoy a smooth, quiet, and eco-friendly ride.",
    price: '800 EGP/hour',
    image: "seacruiser",
  },
  {
    name: "PEDALO",
    description: "Fun pedal-powered watercraft for the whole family. Easy to operate and perfect for leisurely exploration.",
    price: '600 EGP/hour',
    image: "pedalo",
  },
  {
    name: "Stand-Up Paddleboard",
    description: "Stand-up paddleboarding perfect for sea touring or calm inland waterways. Try our SUP yoga for a challenge!",
    price: '600 EGP/hour',
    image: "SUP",
  },
  {
    name: "E-FOILING",
    description: "Cutting-edge electric hydrofoil surfboards. Glide above the water at speeds up to 25 mph.",
    price: '€70 per 30 minutes',
    image: "efoil2",
  },
  {
    name: "Outdoor Fitness",
    description: "Group fitness classes with professional trainers in an open-air setting.",
    price: '300 EGP per class',
    image: "outdoor",
  },
];

// Individual Card Component to manage its own state
function ActivityCard({ activity }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="activity-card-container" onClick={handleFlip}> {/* Container for perspective */}
      <div className={`activity-card-inner ${isFlipped ? 'is-flipped' : ''}`}> {/* This element flips */}
        {/* --- FRONT FACE --- */}
        <div className="activity-card-face activity-card-front">
          <div className="image-container">
            <img
              src={`https://${process.env.REACT_APP_BACKEND}/images/ActivitiesWebpComp/${activity.image}.webp`}
              alt={activity.name}
              loading="lazy"
            />
          </div>
          <h2>{activity.name.toLocaleUpperCase()}</h2>
        </div>

        {/* --- BACK FACE --- */}
        <div className="activity-card-face activity-card-back">
          <h2>{activity.name.toLocaleUpperCase()}</h2>
          <p className="description">{activity.description}</p>
          <p className="price">
            <strong>{activity.price}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}


// Main Activities Component
function Activities() {
  return (
    <div className="activity-list-container"> {/* Renamed for clarity */}
      {activities.map((activity) => (
        <ActivityCard activity={activity} key={activity.name} />
      ))}
    </div>
  );
}

export default Activities;